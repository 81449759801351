interface wbt {
  id?: number
  tag?: string
  name?: string
  stockPrice?: string
  singlePrice?: string
  increment?: string
  rate?: string
  type?: number
  delete?: boolean
  image?: string
}
const wbtData: wbt[] = [
  {
    id: 24,
    tag: 'YYDS',
    name: '拾梦-樱花玛瑙',
    stockPrice: '59,736,000.11',
    singlePrice: '1.31',
    increment: '0.11',
    rate: '9.17%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220120/fmdgipwc7241642659236410.jpg',
    delete: false
  },
  {
    id: 1,
    tag: 'WHT',
    name: '薇美通-珍珠耳钉',
    stockPrice: '84,886,719.94',
    singlePrice: '2.42',
    increment: '0.16',
    rate: '6.20%',
    type: 1,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220211/rlmtewuz5041644557933988.png',
    delete: true
  },
  {
    id: 2,
    tag: 'ATQ',
    name: '青龙盖鼎拓片版画',
    stockPrice: '199,599,995.61',
    singlePrice: '4.99',
    increment: '0.50',
    rate: '9.11%',
    type: 1,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20211103/uwaygmqh8991635919498708.jpg',
    delete: true
  },
  {
    id: 3,
    tag: 'ANC',
    name: '若玫-蔷薇辉石',
    stockPrice: '75,819,541.00',
    singlePrice: '1.66',
    increment: '0.02',
    rate: '1.19%',
    type: 1,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220211/wmpdvjyc4061644545930502.jpeg',
    delete: true
  },
  {
    id: 4,
    tag: 'ZYG',
    name: '君望-虎晴石',
    stockPrice: '238,043,925.00',
    singlePrice: '0.63',
    increment: '0.06',
    rate: '10.53%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220112/yxqkhkev2831641967020040.jpg',
    delete: false
  },
  {
    id: 5,
    tag: 'GHI',
    name: '返璞归真-独山玉',
    stockPrice: '53,917,380.00',
    singlePrice: '0.10',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20211111/wmebxraf2681636613677349.jpg',
    delete: false
  },
  {
    id: 6,
    tag: 'YCPP',
    name: '釉彩流金-釉料',
    stockPrice: '264,223,882.00',
    singlePrice: '8.12',
    increment: '0.32',
    rate: '4.10%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220126/zklcjhca7961643166051664.jpeg',
    delete: false
  },
  {
    id: 7,
    tag: 'MKK',
    name: '惠美-红酸枝',
    stockPrice: '138,854,142.00',
    singlePrice: '1.89',
    increment: '0.21',
    rate: '10.00%',
    type: 1,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220215/hezrxnvw7061644897885013.jpg',
    delete: true
  },
  {
    id: 8,
    tag: 'SYJ',
    name: '漆艺云签',
    stockPrice: '516,420,000.00',
    singlePrice: '12.56',
    increment: '0.03',
    rate: '4.10%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220126/qqcmfocv1511643162723797.jpg',
    delete: false
  },
  {
    id: 9,
    tag: 'HROK',
    name: '月照-花梨木',
    stockPrice: '107,224,200.00',
    singlePrice: '1.80',
    increment: '0.07',
    rate: '4.05%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220214/xwcivykp1271644804028605.jpg',
    delete: false
  },
  {
    id: 10,
    tag: 'DMCS',
    name: '赤炎-石榴石',
    stockPrice: '58,899,905.00',
    singlePrice: '0.19',
    increment: '0.02',
    rate: '11.76%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20210830/sesjzbqm1271630304679134.jpg',
    delete: false
  },
  {
    id: 11,
    tag: 'YHW',
    name: '最忆杭州-金楠颂',
    stockPrice: '103,999,279.70',
    singlePrice: '0.13',
    increment: '0.01',
    rate: '8.33%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20211025/qgteqxfk9791635131156856.png',
    delete: false
  },
  {
    id: 12,
    tag: 'FLQS',
    name: '辰玉-曲谱',
    stockPrice: '156,000,000.00',
    singlePrice: '3.90',
    increment: '0.31',
    rate: '8.64%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20210824/wckzsnkl4091629795225576.jpg',
    delete: false
  },
  {
    id: 13,
    tag: 'BYDT',
    name: '黑金刚武士',
    stockPrice: '71,030,986.87',
    singlePrice: '1.50',
    increment: '0.02',
    rate: '1.35%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220127/rboxdlbt9451643246875895.jpg',
    delete: false
  },
  {
    id: 14,
    tag: 'AHPP',
    name: '古木逢春-紫光檀',
    stockPrice: '37,800,760.00',
    singlePrice: '0.92',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20201028/oooooiyi6231603875279636.jpg',
    delete: false
  },
  {
    id: 15,
    tag: 'HTY',
    name: '浑金璞玉-至臻玉',
    stockPrice: '854,197,131.60',
    singlePrice: '1.08',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220106/rbtabeei2641641432535100.png',
    delete: false
  },
  {
    id: 16,
    tag: 'CRN',
    name: '十里芙蓉-粉水晶',
    stockPrice: '129,717,869.40',
    singlePrice: '2.99',
    increment: '0.11',
    rate: '3.55%',
    type: 1,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20220106/minxicrt3321641432081803.jpg',
    delete: true
  },
  {
    id: 17,
    tag: 'DFQ',
    name: '奇丽艺品-琥珀',
    stockPrice: '324,409,403.00',
    singlePrice: '0.91',
    increment: '0.08',
    rate: '9.64%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20210819/kizymvre9431629358286396.png',
    delete: false
  },
  {
    id: 18,
    tag: 'BXAS',
    name: '姓氏雕塑本姓爱书',
    stockPrice: '50,000,000.00',
    singlePrice: '0.15',
    increment: '0.01',
    rate: '9.64%',
    type: 0,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20210205/hwhuxzcj6601612495901064.jpg',
    delete: false
  },
  {
    id: 19,
    tag: 'ARTYPT',
    name: '艺玉紫砂泥(壶)',
    stockPrice: '8,580,000.00',
    singlePrice: '1.43',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20181119/whgpdwql1061542632478609.jpg',
    delete: false
  },
  {
    id: 20,
    tag: 'TMA001',
    name: '约见时光艺术影印',
    stockPrice: '23,865,461.76',
    singlePrice: '0.84',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20181119/mwsicjao5351542632800469.jpg',
    delete: false
  },
  {
    id: 21,
    tag: 'HPY',
    name: '快乐玉行-玉葫芦',
    stockPrice: '43,700,000.00',
    singlePrice: '0.19',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20190527/smpjlwli7021558950696471.jpg',
    delete: false
  },
  {
    id: 22,
    tag: 'HTT',
    name: '康旅通-艺术影印',
    stockPrice: '186,421,346.70',
    singlePrice: '0.19',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20190107/pfsooxqe5341546865907187.jpg',
    delete: false
  },
  {
    id: 23,
    tag: 'KLT',
    name: '康乐通-白玉扣',
    stockPrice: '409,820,261.11',
    singlePrice: '4.69',
    increment: '0.00',
    rate: '0.00%',
    type: 2,
    image:
      'https://ta.wenbantong.com.cn/share/ta/upload/activity/20211231/msbreexl6121640944777456.jpg',
    delete: false
  }
]

export { wbtData }


import { reactive, toRefs, onBeforeMount, defineComponent } from 'vue'
import { Image as VanImage, Swipe, SwipeItem, Empty } from 'vant'
import WbtList from '../../../components/wbt/list/index.vue'
import { wbtData } from '../../../type/wbt'
// import { getCultureDetailInfo } from '../../../api/culture'

const list = wbtData
export default defineComponent({
  components: { [VanImage.name]: VanImage, Swipe, SwipeItem, Empty },
  name: '',
  setup() {
    console.log('1-开始创建组件-setup')
    const data = reactive({})
    const bannerImg1 = require('./assets/banner.png')
    const bannerImg2 = require('./assets/banner2.png')
    const bannerImg3 = require('./assets/banner3.png')
    const waitImg = require('./assets/wait.png')
    onBeforeMount(() => {
      console.log('2.组件挂载页面之前执行----onBeforeMount')
    })

    const refData = toRefs(data)
    return {
      ...refData,
      list,
      bannerImg1,
      bannerImg2,
      bannerImg3,
      waitImg
    }
  }
})
